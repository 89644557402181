import React from 'react'
import { Container, Typography, Grid, Fade, } from '@mui/material'
import { styled } from '@mui/material/styles'
import { AtlasLogo } from './AtlasLogo'
import { AppleStoreButton } from './AppleStoreButton'
import { GoogleStoreButton } from './GoogleStoreButton'

const ContainerWrapper = styled(Container, { shouldForwardProp: () => true })<{ type: string }>(({ theme }) => ({
	height: '100vh',
	width: '100vw',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	lineHeight: 1.5,
	fontFamily: "OpenSans",
	'div.logo-container-atlas': {
		textAlign: 'center',
		color: '#ffffff !important',
		display: 'flex',
		flexDirection: 'column',

	},
	a: {
		color: '#E3124B',
		textDecoration: 'none'
	},
	'.title': {
		textTransform: 'uppercase',
		fontSize: '2.25rem',
		fontWeight: 'bold',
		marginBottom: '0.5rem',
		lineHeight: '1.2',

	}
}))

export const EventLandingView = () => {
	const title = 'Atlas',
		name = 'Tim Tickets',
		type = 'Default',
		message = 'Tim Tickets has merged into the Atlas app. Please download the atlas app to continue with visitor access.'

	return (
		<ContainerWrapper type={type}>
			<Fade in={true} timeout={250} easing={'ease-in-out'}>
				<div className='logo-container-atlas' style={{ maxWidth: '100%' }}>
					<div className='slide-in-blurred-top' >
						<AtlasLogo sx={{ minWidth: '5rem', margin: 'auto' }} />
						<Typography className='title'>{title}</Typography>
						<Typography className='fade-in'>{name}</Typography>
						<br />
						<Typography className='text-white' variant='body1'>{message}</Typography>
						<br />
					</div>
					<Grid container direction={'row'} alignContent='center' justifyContent={'center'} gap={2}>
						<Grid item>
							<AppleStoreButton />
						</Grid>
						<Grid item>
							<GoogleStoreButton />
						</Grid>
					</Grid>
				</div>
			</Fade>
		</ContainerWrapper>
	)
}
