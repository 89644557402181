
export const GoogleStoreButton = () => {

    const storePageURL = 'https://play.google.com/store/apps/details?id=edu.mit.atlas';
    
    return (
        <div style={{
            color: '#E3124B',
            textDecoration: 'none'
        }}>
            <a href={storePageURL} role='button' target='_blank' aria-label='google play store'>
                <img style={{ height: '50px' }} src={process.env.PUBLIC_URL + '/images/google-play.svg'} alt='Google Play' />
            </a>
        </div>
    )
}