import React from 'react'
import ReactDOM from 'react-dom'
import { /* BrowserRouter as  */Router, Route, Switch } from 'react-router-dom'
import History from './Common/History'
import { StoreProvider } from 'easy-peasy'
import { store } from 'Store'
import { Initialization, Logout } from 'TemplateConstants/components'
import reportWebVitals from './reportWebVitals'
import { QrDownload } from 'Components/QrDownload'
import { EventLandingView } from 'TemplateConstants/components/EventLandingView'

ReactDOM.render(
	<EventLandingView />,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
